<template>
  <div id="page-01-arrow" ref="arrowWrapper">
    <svg
      id="curve-svg"
      viewBox="0 0 1189.1943 495.92637"
      :style="{ opacity, width, height }"
    >
      <path
        id="curve-path-1"
        d="M 0.0722124 1.69586 C 16.9477 58.2901 44.4756 111.03 81.2071 157.247 C 118.036 203.955 163.56 243.12 215.211 272.602 C 268.665 302.949 328.425 321.946 389.47 328.961 C 451.063 336.173 513.551 330.972 573.107 313.608 C 621.265 299.432 684.361 271.678 685.294 212.863 C 685.607 191.389 677.195 168.083 657.828 156.848 C 635.541 143.748 607.45 151.686 589.511 168.614 C 569 187.992 563.188 217.699 564.91 244.784 C 566.923 275.38 577.742 305.14 593.723 331.12 C 626.917 385.179 680.602 421.801 738.73 444.896 C 800.191 469.366 867.055 482.054 932.809 487.112 C 1000.84 492.329 1069.27 488.29 1136.29 475.15 C 1152.77 471.87 1169.24 468.079 1185.51 463.72"
      />
      <path
        id="curve-path-2"
        d="M 1151.08 445.048 L 1187.11 461.823 L 1186.56 459.571 L 1161.98 493.49 C 1160.86 495.002 1163.2 496.881 1164.32 495.369 L 1188.9 461.45 C 1189.41 460.824 1189.23 459.819 1188.6 459.312 C 1188.47 459.255 1188.44 459.162 1188.31 459.105 L 1152.27 442.33 C 1150.55 441.496 1149.26 444.251 1151.08 445.048 Z"
      />
    </svg>
  </div>
</template>

<script>
import { gsap } from "../controls/Gsap";
import { isMobile } from "../util";

export default {
  name: "HomePage01Arrow",
  data() {
    return {
      opacity: 0,
      width: 0,
      height: 0,
    };
  },
  methods: {
    resize() {
      if (isMobile()) return;

      const curveContainer = this.$refs.arrowWrapper;
      const maxHeight = curveContainer.offsetHeight;
      const maxWidth = curveContainer.offsetWidth;
      if (maxHeight < 100 || maxWidth < 240) {
        this.opacity = 0; // не рисовать, если размер слишком мелкий
        return;
      }

      this.opacity = 1;
      this.width = Math.min(maxWidth, (maxHeight * 240) / 100);
      this.height = Math.min(maxHeight, (maxWidth * 100) / 240);

      this.$nextTick(() => {
        gsap.to("#curve-path-2", {
          duration: 0,
          opacity: 0, // Спрятать "хвостик" стрелки
          onComplete: () => {
            gsap.to("#curve-svg", {
              duration: 0,
              opacity: 1, // Показать контейнер со стрелкой
              onComplete: () => {
                gsap.from("#curve-path-1", {
                  duration: 1.25,
                  drawSVG: 0, // Анимировать стрелку 0 -> 1
                  ease: "power1.inOut",
                  onComplete: () => {
                    gsap.to("#curve-path-2", {
                      duration: 0,
                      opacity: 1, // Показать "хвостик" стрелки
                    });
                  },
                });
              },
            });
          },
        });
      });
    },
  },
  mounted() {
    this.$eventBus.on("resize", this.resize);
    setTimeout(() => this.resize());
  },
  unmounted() {
    this.$eventBus.off("resize", this.resize);
  },
};
</script>

<style lang="scss">
@import "@/styles/theme-default";

#page-01-arrow {
  display: inline-block;
  width: 100%;
  height: 100%;
  // border: thin dotted red;
  position: relative;

  #curve-svg {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0 0 0 10%;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
  }

  #curve-path-1 {
    fill: none;
    stroke: #2671dd;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  #curve-path-2 {
    fill: #2671dd;
    stroke: #2671dd;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
</style>
