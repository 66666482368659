<script>
import SlideshowLayout from "../layouts/slideshow";
import HomePage01Left from "./page-01-left";
import HomePage01Right from "./page-01-right";
import HomePage02 from "./page-02";
import HomePage03 from "./page-03";
import HomePage04 from "./page-04";
import HomePage05 from "./page-05";

export default {
  name: "HomePage",
  extends: SlideshowLayout,
  data() {
    return {
      pages: [
        {
          left: "HomePage01Left",
          right: "HomePage01Right",
        },
        "HomePage02",
        "HomePage03",
        "HomePage04",
        "HomePage05",
      ],
    };
  },
  components: {
    HomePage01Left,
    HomePage01Right,
    HomePage02,
    HomePage03,
    HomePage04,
    HomePage05,
  },
};
</script>
