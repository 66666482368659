<template>
  <div id="contacts-main">
    <div>
      <div id="organization">АО «Оператор информационной системы»</div>
      <div id="title">Контакты</div>
    </div>
    <div>
      <div id="address-1">
        ИНН 7704455470<br />
        Юридический адрес:<br />
        119435, г.. Москва, ул. Большая Пироговская, 27 стр. 4, пом 3, к.1
      </div>
      <div id="address-2">
        info@oisrf.ru<br />
        Отдел по работе со СМИ <a href="mailto:pr@oisrf.ru">pr@oisrf.ru</a>
      </div>
      <div id="address-3">
        Отдел по работе с персоналом
        <a href="mailto:job@oisrf.ru">job@oisrf.ru</a><br />
        Отдел по работе с корпоративными клиентами
        <a href="mailto:b2b@oisrf.ru">b2b@oisrf.ru</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts-Main",
};
</script>

<style lang="scss">
@import "@/styles/theme-default";

#contacts-main {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: $backgroud-default;
  border-radius: 2.189em;
  padding: 3.125em 3.5625em;
  max-width: 990px;
  max-height: 440px;
  color: #2c50e6;

  & > div:first-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-bottom: 5em;
  }
  & > div:last-child {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
  }

  #organization {
    font-weight: 600;
    font-size: 3em;
    max-width: 95%;
    line-height: 115%;
    letter-spacing: -0.01em;
  }
  #title {
    font-size: 2.1875em;
    line-height: 137%;
    letter-spacing: -0.01em;
    text-align: right;
  }
  #address-1,
  #address-2,
  #address-3 {
    font-size: 1em;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.01em;
    a {
      font-weight: 600;
    }
  }
}
</style>
