<template>
  <div>
    <router-view />
    <resize-observer @notify="onResize" :showTrigger="true" />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      resizeTimeout: null,
    };
  },
  methods: {
    onResize({ width, height }) {
      if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.$eventBus.emit("resize", { width, height });
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/theme-default.scss";

#app {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-black;

  margin: 0;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  display: block;

  & > div {
    margin: 0;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    display: block;
  }
}
</style>
