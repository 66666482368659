<template>
  <div id="top-menu" :class="topMenuClass">
    <div>
      <div class="about-menu">
        <router-link to="/">о нас</router-link>
      </div>
      <div class="right-menu">
        <router-link to="/contacts">контакты</router-link>
      </div>
      <div class="right-menu">
        <router-link to="/openings">вакансии</router-link>
      </div>
      <!--div class="right-menu">команда</div-->
    </div>
  </div>
</template>

<script>
export default {
  name: "TopMenu",
  props: ["currentSlide"],
  computed: {
    topMenuClass() {
      const c = {};
      c[`top-menu-slide-${this.currentSlide}`] = true;
      return c;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/theme-default";
@import "./page-base";

#top-menu {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1em 1em 0 0;
  line-height: 1.1875em;
  letter-spacing: -0.01em;
  color: $text-white;
  text-align: right;
  font-size: 1em;
  & > div {
    display: block;
    margin: 0 auto;
  }
  &.top-menu-slide-2,
  &.top-menu-slide-4 {
    .right-menu {
      color: $text-black;
    }
  }
  &.top-menu-slide-2,
  &.top-menu-slide-4 {
    .about-menu {
      color: $text-black;
    }
  }
  .about-menu,
  .right-menu {
    a {
      color: $text-white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// @media (min-width:961px)
// @media (min-width:1025px)
// @media (min-width:1281px)
@media (min-width: 961px) {
  #top-menu {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 30px auto 0 auto;
    & > div {
      display: block;
      width: calc(100% - 32px);
      max-width: $page-internal-max-width;
      margin: 0 auto;
      transition: padding 1s linear;
      @media (max-width: 1380px) {
        padding: 0 6%;
      }

      box-sizing: border-box;
      // border: thin dotted red;
    }
    .about-menu {
      float: left;
      color: $text-black;
      position: relative;
      a::after {
        content: "";
        position: absolute;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../../assets/images/about-circle.svg);
        fill: $text-black;
        width: 6.5em;
        height: 2.375em;
        left: -1.25em;
        top: -0.625em;
      }
      a {
        color: $text-black;
      }
    }
    .right-menu {
      float: right;
      color: $text-black;
      margin: 0 1em;
      a {
        color: $text-black;
      }
    }

    &.top-menu-slide-0,
    &.top-menu-slide-1,
    &.top-menu-slide-2 {
      // &.top-menu-slide-4 {
      .right-menu {
        color: $text-white;
        a {
          color: $text-white;
        }
      }
    }
    &.top-menu-slide-1,
    &.top-menu-slide-2 {
      // &.top-menu-slide-4 {
      .about-menu {
        color: $text-white;
        a {
          color: $text-white;
        }
        a::after {
          background-image: url(../../assets/images/about-circle-white.svg);
        }
      }
    }
  }
}
</style>
