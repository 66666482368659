<template>
  <div id="contacts">
    <div>
      <div>
        <div>
          <contacts-main />
          <div>
            <contacts-map-01 />
            <contacts-map-02 />
          </div>
        </div>
      </div>
      <div class="linked-apps">
        <contacts-app-01 />
        <contacts-app-02 />
      </div>
    </div>
  </div>
</template>

<script>
import ContactsMain from "./main.vue";
import ContactsMap01 from "./map-01.vue";
import ContactsMap02 from "./map-02.vue";
import ContactsApp01 from "./app-01.vue";
import ContactsApp02 from "./app-02.vue";

export default {
  name: "ContactsView",
  components: {
    ContactsMain,
    ContactsMap01,
    ContactsMap02,
    ContactsApp01,
    ContactsApp02,
  },
};
</script>

<style lang="scss">
@import "../../../styles/theme-default";

#contacts {
  margin: 0;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-light-grey;
  padding: 0 1em;

  & > div {
    // border: thin dotted red;
    display: grid;
    // grid-gap: 1.875em;
    grid-template-columns: 3.67fr 1fr;
    width: 100%;
    // height: 100%;
    max-width: 1300px;
    max-height: 740px;
    // padding: 6.875em 3.75em;
    box-sizing: border-box;

    & > .div:first-child {
      display: grid;
    }
    & > .div:last-child {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    #contacts-main {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
    }
    #contact-map-01,
    #contact-map-02 {
      width: 50%;
      max-width: 50%;
      box-sizing: border-box;
      max-height: 240px;
      padding: 7.5em 0;
      border: thin solid #2c50e6;
    }

    .linked-apps {
      display: grid;
      grid-template-rows: 1fr 1fr;
    }
    #contact-app-01,
    #contact-app-02 {
      width: 100%;
      box-sizing: border-box;
      max-height: 100%;
      padding: 7.5em 0;
      border: thin solid #2c50e6;
    }
  }
}
</style>
