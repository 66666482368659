import { gsap as _gsap } from "gsap";
import DrawSVGPlugin from "./DrawSVGPlugin";
import MotionPathPlugin from "./MotionPathPlugin";
_gsap.registerPlugin(DrawSVGPlugin);
_gsap.registerPlugin(MotionPathPlugin);

// https://greensock.com/forums/topic/8237-blur-filters/?do=findComment&comment=107702&_rid=112964
(function () {
  const blurProperty = _gsap.utils.checkPrefix("filter"),
    blurExp = /blur\((.+)?px\)/,
    getBlurMatch = (target) =>
      (_gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];

  _gsap.registerPlugin({
    name: "blur",
    get(target) {
      return +getBlurMatch(target)[1] || 0;
    },
    init(target, endValue) {
      let data = this,
        filter = _gsap.getProperty(target, blurProperty),
        endBlur = "blur(" + endValue + "px)",
        match = getBlurMatch(target)[0],
        index;
      if (filter === "none") {
        filter = "";
      }
      if (match) {
        index = filter.indexOf(match);
        endValue =
          filter.substr(0, index) +
          endBlur +
          filter.substr(index + match.length);
      } else {
        endValue = filter + endBlur;
        filter += filter ? " blur(0px)" : "blur(0px)";
      }
      data.target = target;
      data.interp = _gsap.utils.interpolate(filter, endValue);
    },
    render(progress, data) {
      data.target.style[blurProperty] = data.interp(progress);
    },
  });
})();

export const gsap = _gsap;
