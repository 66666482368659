<template>
  <div id="opengins" ref="openingWrapper">
    <div
      v-for="(opening, index) in openings"
      :key="index"
      class="opening-box"
      :class="{
        'selected-opening': selectedOpening === index,
        'selected-position': selectedOpening === index && !!selectedPosition,
      }"
      :style="`background: ${
        selectedOpening === index ? '#fff' : opening.color
      }; width: ${width[index]}px; height: ${height[index]}px`"
      @click="(e) => onClickOpening(index, e.target.getAttribute('opening'))"
    >
      <div class="opening-title">{{ opening.title }}</div>
      <div class="opening-pad" />
      <div class="opening-count">{{ opening.count }}</div>
      <ul class="opening-list" v-if="opening.data">
        <li
          v-for="position in opening.data"
          :key="position.url"
          :opening="position.url"
          :style="`display: ${
            !selectedPosition || selectedPosition === position.url
              ? 'flex'
              : 'none'
          }`"
        >
          {{ position.title }}
        </li>
      </ul>
      <iframe
        v-if="selectedPosition && selectedOpening === index"
        :src="selectedPosition"
      />
    </div>
  </div>
</template>

<script>
import openings from "./index.js";

export default {
  name: "CurrentOpenings",
  data: () => ({
    selectedOpening: 2,
    selectedPosition: null,
    width: [0, 0, 0, 0, 0, 0],
    height: [0, 0, 0, 0, 0, 0],
    developer: null,
    openings,
  }),
  methods: {
    resize() {
      const width = Math.floor((this.$refs.openingWrapper.offsetWidth - 1) / 3);
      const height = Math.floor(
        (this.$refs.openingWrapper.offsetHeight - 1) / 2
      );
      if (this.selectedDeveloper) {
        const floor = Math.floor(this.selectedOpening / 2);
        for (let i = 0; i < 6; i++) {
          if (i === 2) {
            this.height[i] = Math.floor(height * 2);
          } else if (i === 3) {
            this.height[i] = 0;
          } else {
            this.height[i] = Math.floor(height);
          }
          this.width[i] = Math.floor(
            width * (Math.floor(i / 2) === floor ? 1.5 : 0.75)
          );
        }
      } else if (this.selectedOpening >= 0) {
        const floor = Math.floor(this.selectedOpening / 2);
        for (let i = 0; i < 6; i++) {
          this.height[i] = Math.floor(
            height * (i % 2 === this.selectedOpening % 2 ? 1.3 : 0.7)
          );
          this.width[i] = Math.floor(
            width * (Math.floor(i / 2) === floor ? 1.4 : 0.8)
          );
        }
      } else {
        for (let i = 0; i < 6; i++) {
          this.width[i] = Math.floor(width);
          this.height[i] = Math.floor(height);
        }
      }
    },
    onResize() {
      this.resize();
    },
    onClickOpening(selectedOpening, url) {
      // console.debug("onClickOpening", component);
      this.selectedOpening = selectedOpening;
      this.selectedPosition = url;
      this.resize();
    },
  },
  mounted() {
    this.resize();
  },
};
</script>

<style lang="scss">
@import "./openings";
</style>
