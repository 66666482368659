<template>
  <div id="contact-app-01">
    <div>
      <a target="_black" href="https://dom.gosuslugi.ru">
        Государственная информационная система жилищно-коммунального хозяйства
        (ГИС ЖКХ)
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact-App-01",
};
</script>

<style lang="scss">
@import "@/styles/theme-default";

#contact-app-01 {
  margin: 0 0 0 0;
  width: 100%;
  background-color: #486dd5;
  display: inline-block;
  padding-top: 5em;
  border-radius: 2.1875em;
  position: relative;
  & > div {
    display: inline-block;
    width: 85%;
    font-weight: 600;
    font-size: 1.25em;
    line-height: 117%;
    letter-spacing: -0.01em;
    color: #fff;
    position: absolute;
    bottom: 1em;
    right: 0;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>
