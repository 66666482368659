<script>
import SlideshowPageBase from "./page-base";

export default {
  name: "Page-01",
  data() {
    return {
      pageId: "page-01",
    };
  },
  extends: SlideshowPageBase,
};
</script>

<style lang="scss">
@import "@/styles/theme-default";
@import "@/styles/effects";
@import "./page-base";

#page-01 {
  & > div:first-child {
    margin: 0;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;

    & > section {
      height: 100%;
      &:last-child {
        background-color: $backgroud-blue;
        border-top-left-radius: 5em;
        border-bottom-left-radius: 5em;
      }
    }
  }
  .slideshow-page-content {
    display: none;
  }
}

@media (min-width: 961px) {
  #page-01 {
    & > div:first-child {
      & > section {
        height: 100%;
        &:last-child {
          background-color: $backgroud-blue;
          border-top-left-radius: 5.625em;
          border-bottom-left-radius: 5.625em;
        }
      }
    }
    .slideshow-page-content {
      width: 88.57%;
      max-width: $page-content-max-width;
    }
  }
}

@media (min-width: 1556px) {
  #page-01 {
    .slideshow-page-content {
      left: calc(50% - 600px);
    }
  }
}
</style>
