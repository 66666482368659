<template>
  <div id="contact-app-02">
    <div>Мобильное приложение «Госуслуги Дом»</div>
  </div>
</template>

<script>
export default {
  name: "Contact-App-02",
};
</script>

<style lang="scss">
@import "@/styles/theme-default";

#contact-app-02 {
  margin: 0 0 0 0;
  height: 100%;
  width: 100%;
  background-color: #2c50e6;
  display: inline-block;
  padding-top: 5em;
  border-radius: 2.1875em;
  position: relative;

  & > div {
    display: inline-block;
    width: 85%;
    font-weight: 600;
    font-size: 1.25em;
    line-height: 117%;
    letter-spacing: -0.01em;
    color: #fff;
    position: absolute;
    bottom: 1em;
    right: 0;
  }
}
</style>
