<template>
  <div class="home-page" id="home-page-03">
    <div>
      <div class="goal">
        Модернизация и оптимизация инструментов для формирования клиентского
        опыта и развития государственной информационной системы ЖКХ. Создавая
        набор цифровых инструментов, мы формируем позицию активного потребителя.
      </div>
      <div class="goal-2">
        <svg id="fancy-arrow" viewBox="0 0 845.6 192.5">
          <g>
            <g>
              <path
                d="m 843.1,188.7 c -63.5,-10.4 -117.9,-42.8 -178.6,-61.1 -56.6,-17.1 -129.6,-17.7 -169.6,17 -7.4,6.3 -2.9,16.8 7.7,18 14.6,1.6 33.4,-6.6 35.7,-19.6 3.5,-19.3 -17.5,-36.4 -39.9,-43.6 C 450.8,84.1 396.6,98.1 351,117 305.4,135.9 261.4,160.1 210.7,166.3 156.8,172.9 99.5,156.8 62.2,124.7 24.9,92.6 8.7,45.3 20.3,2.2"
              />
            </g>
            <g>
              <polyline points="1.8,32.9 20.3,2.2 35.8,32.9" />
            </g>
          </g>
        </svg>
        <div>Нашей первостепенной задачей является</div>
      </div>
      <div class="goal-3">Дальше только больше</div>
    </div>
  </div>
</template>

<script>
import { gsap } from "../controls/Gsap";
import { isMobile } from "../util";

export default {
  name: "HomePage03",
  data() {
    return {
      wasShown: false,
    };
  },
  methods: {
    isMobile,
    onPageShow() {
      if (this.wasShown) return;
      this.wasShown = true;
      this.animate();
    },
    animate() {
      if (isMobile()) return; // Если мобильная версия, то без анимации

      gsap.to("#home-page-03 #fancy-arrow polyline", {
        duration: 0,
        opacity: 0,
        onComplete: () => {
          gsap.to("#home-page-03 #fancy-arrow path", {
            duration: 0,
            opacity: 1,
            onComplete: () => {
              gsap.from("#home-page-03 #fancy-arrow path", {
                duration: 1.5,
                drawSVG: "0",
                ease: "power1.inOut",
                onComplete: () => {
                  gsap.to("#home-page-03 #fancy-arrow polyline", {
                    duration: 0,
                    opacity: 1,
                  });
                },
              });
            },
          });
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/theme-default";

.home-page {
  display: inline-block;
  height: 100%;
  width: 100%;
}

#home-page-03 {
  text-align: left;
  .goal {
    margin: 10% 20% 5% 0;
    font-size: 2.25em;
    line-height: 115%;
    letter-spacing: -0.01em;
    color: $text-white;
    padding-left: 0.5em;
  }
  .goal-2,
  .goal-3 {
    font-weight: normal;
    font-size: 2.33em;
    line-height: 114%;
    letter-spacing: -0.01em;
    color: $text-white;

    & > div {
      padding-left: 60%;
      margin-bottom: 1em;
      text-align: left;
    }
  }
  .goal-2 {
    padding-left: 15%;
  }
}

@media (min-width: 961px) {
  #home-page-03 {
    & > div {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    .goal {
      margin: 10% 30% 5% 0;
      padding-left: 0;
    }
    .goal-2 {
      & > div {
        padding-left: 0;
        float: right;
        max-width: 40%;
        transform: translate(1em, 2em);
      }
    }
  }
}

#fancy-arrow {
  width: 60%;
  display: inline-block;
  margin: auto;
  path,
  polyline {
    fill: none;
    stroke: $text-white;
    stroke-width: 4;
  }
}
</style>
