<template>
  <div class="plain-layout">
    <div>
      <top-menu :current-slide="-1" />
      <router-view />
    </div>
  </div>
</template>

<script>
import TopMenu from "@/components/pages/top-menu";

export default {
  name: "PlainLayout",
  components: { TopMenu },
};
</script>

<style lang="scss">
@import "@/styles/theme-default";

.plain-layout {
  margin: 0;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-light-grey;

  & > div {
    // border: thin dotted red;
    width: 100%;
    height: calc(100% - 6em);
    max-width: $page-plain-max-width;
    max-height: $page-plain-max-height;
    box-sizing: border-box;
    margin: 4em 0 1em 0;
  }
}
</style>
