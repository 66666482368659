<template>
  <div class="slideshow-page-right" id="home-page-01-right">
    <div class="startup">
      Работай в большом<br />стартапе вместе<br />с нами
    </div>
    <div class="arrow-big" @click="$emit('next-slide')" />
  </div>
</template>

<script>
export default {
  name: "HomePage01Right",
};
</script>

<style lang="scss">
@import "@/styles/theme-default";

#home-page-01-right {
  // background-color: rgba(255, 0, 0, 0.15);
  text-align: left;

  .startup {
    /* Работай в большом стартапе вместе с нами */
    position: absolute;
    left: 3em;
    bottom: 7em;
    font-size: 1.75em;
    line-height: 121%;
    color: $text-white;
  }

  .arrow-big {
    /* Ellipse 1 */

    position: absolute;
    width: 3.5em;
    height: 3.5em;
    left: 5.5em;
    bottom: 5.5em;
    background-color: $backgroud-default;
    border-radius: 50%;
    cursor: pointer;

    &::after {
      content: "";
      display: inline-block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-image: url(../../assets/images/arrow-big.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@media (min-width: 961px) {
  #home-page-01-right {
    width: 71.42%;
  }
  .arrow-big {
    &::after {
      -webkit-animation-name: opacity-0-0-0-0-1;
      animation-name: opacity-0-0-0-0-1;
      animation-duration: 1.5s;
    }
  }
}

@media (min-width: 1556px) {
  #home-page-01-right {
    width: calc(500px - 10%);
  }
}
</style>
