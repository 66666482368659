<template>
  <div
    id="fly-house-wrapper"
    ref="flyHouseWrapper"
    :class="{ 'too-small-to-show': scale < 0.5 }"
  >
    <div
      :style="{
        transform:
          scale >= 0.5 ? `scale(${scale}) translateX(${getOffset()}px)` : '',
      }"
    >
      <div v-if="visible" id="box-with-ellipse"></div>
      <div v-if="visible" id="green-box"></div>
      <img
        v-if="visible"
        id="building-in-ellipse"
        :src="require('../../assets/images/v2/house.png')"
      />
      <div id="fly-house-message">{{ message }}</div>
      <div v-if="visible" id="arrow-dashed"></div>
    </div>
  </div>
</template>

<script>
import { gsap } from "../controls/Gsap";

export default {
  name: "Fly-House",
  props: ["message"],
  data() {
    return {
      visible: true,
      scale: 1,
    };
  },
  methods: {
    getOffset() {
      try {
        return (this.scale - 0.75) * this.$refs.flyHouseWrapper.clientWidth;
      } catch {
        return 0;
      }
    },
    animate(duration) {
      if (this.scale < 1) return; // Если нельзя отрисовать в полный размер, то не анимировать картинку

      gsap.to("#building-in-ellipse", {
        duration,
        motionPath:
          "M 1.13598 -0.0883876 C 5.95141 -16.7029 13.8362 -31.541 23.8898 -42.4605 C 38.2074 -58.004 57.2118 -65.2401 75.1318 -62.5494",
      });
    },
    resize() {
      const scale = Math.min(
        this.$refs.flyHouseWrapper.offsetWidth / 560,
        this.$refs.flyHouseWrapper.offsetHeight / 310,
        1
      );

      if (scale < 0.5) this.visible = false;
      this.scale = scale;
    },
  },
  mounted() {
    this.$eventBus.on("resize", this.resize);
    this.resize(true /* noanimate */);
  },
  unmounted() {
    this.$eventBus.off("resize", this.resize);
  },
};
</script>

<style lang="scss">
@import "@/styles/theme-default";

#fly-house-wrapper {
  // border: thin dotted red;
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;

  & > div {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  #box-with-ellipse {
    display: inline-block;
    height: 180px;
    width: 180px;
    background: $basic-grey;
    position: absolute;
    bottom: 0;
    left: 0;

    &::after {
      content: "";
      display: inline-block;
      height: 197px;
      width: 136px;
      background: $dark-blue;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  #arrow-dashed {
    display: inline-block;
    height: 67px;
    width: 240px;
    position: absolute;
    bottom: 180px;
    left: 190px;
    background-image: url(../../assets/images/arrow-dash.png);
    background-repeat: no-repeat;
    background-size: contain;
  }

  #building-in-ellipse {
    display: inline-block;
    height: 163px;
    width: 163px;
    position: absolute;
    bottom: 82px;
    left: 76px;
  }

  #green-box {
    display: none;
    position: absolute;
    display: inline-block;
    width: 60px;
    height: 60px;
    left: 400px;
    bottom: 205px;
    background-color: $backgroud-green;
  }

  #fly-house-message {
    position: absolute;
    display: inline-block;
    width: 300px;
    height: fit-content;
    font-size: 2.75em;
    line-height: 115%;
    color: $text-white;
    text-align: left;
    bottom: 0;
    right: 0;
    // border: thin dotted red;
    vertical-align: bottom;
  }
}

@media (min-width: 961px) {
  #fly-house-wrapper {
    & > div {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    #building-in-ellipse {
      height: 163px;
      width: 163px;
      position: absolute;
      bottom: 12px;
      left: 6px;
    }

    #fly-house-message {
      font-size: 1.75em;
    }
  }
}
/*
@media (min-width: 761px) {
  #fly-house-wrapper {
    display: inline-block;
  }
}
*/
#fly-house-wrapper.too-small-to-show {
  width: 100%;
  #box-with-ellipse,
  #arrow-dashed,
  #building-in-ellipse,
  #green-box {
    display: none;
  }
  #fly-house-message {
    font-size: 1.75em;
    left: 10%;
    width: 90%;
  }
}
</style>
