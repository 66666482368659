import { createApp } from "vue";
import mitt from "mitt";
import Vue3Resize from "vue3-resize";
import "vue3-resize/dist/vue3-resize.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/main.scss";

const app = createApp(App);
app.config.globalProperties.$eventBus = mitt();
app.use(store).use(router).use(Vue3Resize).mount("#app");
