<script>
import SlideshowPageBase from "./page-base";

export default {
  name: "Page-03",
  data() {
    return {
      pageId: "page-03",
    };
  },
  extends: SlideshowPageBase,
};
</script>

<style lang="scss">
@import "@/styles/theme-default";
@import "./page-base";

#page-03 {
  background-color: $backgroud-default;
  display: grid;
  grid-template-columns: 71.43% 28.57%;

  & > div:first-child > section {
    height: 100%;
    &:first-child {
      background-color: $backgroud-blue2;
      border-top-right-radius: 5.625em;
      border-bottom-right-radius: 5.625em;
      box-sizing: content-box;
      border-left: 2px solid $backgroud-blue2;
      transform: translateX(-2px);
    }
  }
}

@media (min-width: 961px) {
  #page-03 {
    grid-template-columns: 71.43% 28.57%;

    .slideshow-page-content {
      transform: translateX(-14.825%);
      width: 77.15%;
      max-width: $page-content-max-width;
    }
  }
}

@media (min-width: 1556px) {
  #page-03 {
    .slideshow-page-content {
      transform: unset;
      left: calc(27% - 600px);
    }
  }
}
</style>
