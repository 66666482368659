<template>
  <div class="slideshow-page-left" id="home-page-01-left">
    <div class="vendor">АО «Оператор информационной системы»</div>
    <div class="caption">Наша миссия</div>
    <div class="mission">
      глобальная трансформация рынка&nbsp;&nbsp;<span class="outline-26"
        >ЖКХ</span
      >
    </div>
    <div class="arrow">
      <home-page-01-arrow />
    </div>
  </div>
</template>

<script>
import HomePage01Arrow from "./page-01-arrow";

export default {
  name: "HomePage01Left",
  components: { HomePage01Arrow },
};
</script>

<style lang="scss">
@import "@/styles/theme-default";

#home-page-01-left {
  // color: green;
  // background-color: rgba(0, 255, 0, 0.15);
  text-align: left;
  padding-left: 12%;
  display: flex;
  flex-flow: column;

  .vendor {
    /* АО «Оператор информационной системы» */

    max-width: 45%;
    margin: 35% 0 0 5%;
    font-size: 1em;
    line-height: 117%;
    color: $text-black;
  }

  .caption {
    /* Наша миссия */

    margin: 10% 0 0 5%;
    font-size: 1em;
    line-height: 200%;
    color: $backgroud-blue;
  }

  .mission {
    /* глобальная трансформация рынка жкх */

    max-width: 47%;
    margin: 1% 0 0 5%;
    font-size: 2em;
    line-height: 118%;
    color: $backgroud-blue;
  }

  .arrow {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 0 10% 10%;
  }
}

@media (min-width: 961px) {
  #home-page-01-left {
    .vendor {
      /* АО «Оператор информационной системы» */

      max-width: 45%;
      margin: 15% 0 0 16px;
      font-size: 1.75em;
      line-height: 121%;
      color: $text-black;
    }

    .caption {
      /* Наша миссия */

      margin: 10% 0 0 16px;
      font-size: 1em;
      line-height: 200%;
      color: $backgroud-blue;
    }

    .mission {
      /* глобальная трансформация рынка жкх */

      max-width: 47%;
      margin: 1% 0 0 16px;
      font-size: 3.625em;
      line-height: 116%;
      color: $backgroud-blue;
    }

    .outline-26 {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        // border: thin dotted red;
        // background-image: url(../../assets/images/outline-26.svg);
        // background-image: url(../../assets/images/jkh-outline.svg);
        background-image: url(../../assets/images/UX_sw_03-2.svg);
        background-size: contain;
        background-repeat: no-repeat;
        width: 3em;
        height: 1.6em;
        left: -0.4em;
        top: -0.4em;
      }
    }
  }
}

@media (min-width: 1556px) {
  #home-page-01-left {
    width: calc(700px + 10%);
  }
}
</style>
