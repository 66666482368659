<template>
  <div :id="pageId" class="slideshow-page">
    <div>
      <section>
        <slot name="left" />
      </section>
      <section>
        <slot name="right" />
      </section>
    </div>
    <div class="slideshow-page-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideshowPageBase",
};
</script>

<style lang="scss">
@import "@/styles/theme-default";
@import "./page-base";

.slideshow-page {
  margin: 0;
  height: 100%;
  width: 100%;
  position: relative;
  display: inline-block;

  .slideshow-page-content {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .slideshow-page-left {
    position: absolute;
    display: inline-block;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .slideshow-page-right {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > div > section {
    position: relative;
  }
}

@media (min-width: 961px) {
  .slideshow-page {
    .slideshow-page-content {
      width: 100%;
      max-width: $page-content-max-width;
      margin-top: 5em;
      height: calc(100% - 5em);
    }
    .slideshow-page-left {
      height: calc(100% - 5em);
    }
    .slideshow-page-right {
      height: calc(100% - 5em);
    }
  }
}
</style>
