import { createRouter, createWebHistory } from "vue-router";
// import TeamView from "../views/TeamView.vue";

import PlainLayout from "../components/layouts/plain-layout.vue";
// import PageAbout from "../components/pages/page-about.vue";

import CurrentOpenings from "../components/pages/openings/index.vue";
// import HomeView from "../views/HomeView.vue";
import SlideshowView from "../components/home";

const routes = [
  /*
  {
    path: "/team",
    name: "team",
    component: TeamView,
  },
  {
    path: "/contacts",
    name: "contacts",
    component: ContactsView,
  },
*/
  {
    path: "/",
    name: "root",
    component: PlainLayout,
    children: [
      {
        path: "contacts",
        name: "contacts",
        component: () =>
          import(
            /* webpackChunkName: "contacts" */ "../components/pages/contacts"
          ),
      },
      {
        path: "openings",
        name: "openings",
        component: CurrentOpenings,
      },
      {
        path: "documents",
        name: "documents",
        component: () =>
          import(
            /* webpackChunkName: "documents" */ "../components/pages/documents/index.vue"
          ),
      },
      {
        path: "security-policy",
        name: "security-policy",
        component: () =>
          import(
            /* webpackChunkName: "security-policy" */ "../components/pages/documents/security-policy.vue"
          ),
      },
      {
        path: "/",
        redirect: "/home",
      },
    ],
  },
  /*
  {
    path: "/about",
    name: "about",
    component: HomeView,
  },
  */
  {
    path: "/home",
    name: "home",
    component: SlideshowView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
