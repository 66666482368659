<template>
  <div class="home-page" id="home-page-02">
    <div class="mission">
      Наша цель:
      <span v-if="isMobile()">устранить цифровое неравенство</span>
      <span v-else>
        <br />
        <span class="mission-outline">
          устранить&nbsp;цифровое&nbsp;неравенство
          <div>
            <svg
              viewBox="0 0 850 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="mission-outline-path"
                d="M1 13.5L109.5 4.5L96.5 13.5L262 4.5L244.5 13.5L421.5 4.5V13.5L577 1L594.5 9L849 1"
              />
            </svg>
          </div>
        </span>
      </span>
      среди жителей Российской Федерации, предоставив всем без исключения
      необходимые цифровые инструменты
    </div>

    <div class="page-02-images">
      <div>
        <fly-house
          ref="flyHouse"
          message="У нас есть уникальная позиция для трансформации рынка жкх"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "../controls/Gsap";
import { isMobile } from "../util";
import FlyHouse from "./page-02-flyhouse";

export default {
  name: "HomePage02",
  data() {
    return {
      wasShown: false,
    };
  },
  methods: {
    isMobile,
    onPageShow() {
      if (this.wasShown) return;
      this.wasShown = true;
      this.animate();
    },
    animateFlyHouse() {
      if (this.$refs.flyHouse) this.$refs.flyHouse.animate(1.25);
    },
    animate() {
      if (isMobile()) return; // Если мобильная версия, то без анимации

      gsap.to(".mission-outline svg", {
        duration: 0,
        opacity: 1,
        onComplete: () => {
          gsap.from("#mission-outline-path", {
            duration: 1.25,
            drawSVG: "0",
            ease: "power1.inOut",
            onComplete: () => this.animateFlyHouse(),
          });
        },
      });
    },
  },
  components: { FlyHouse },
};
</script>

<style lang="scss">
@import "@/styles/theme-default";

.home-page {
  display: inline-block;
  height: 100%;
  width: 100%;
  // border: thin dotted red;
  // color: red;
  // background-color: rgba(255, 0, 0, 0.15);
  // font-size: 100pt;
  // text-align: center;
  // padding-top: 50%;
}

#home-page-02 {
  display: flex;
  flex-direction: column;
  .mission {
    // width: 80%;
    margin: 10% 0 0;
    padding-left: 0.5em;
    font-size: 2.25em;
    line-height: 115%;
    letter-spacing: -0.01em;
    text-align: left;
    color: $text-white;
  }
  .mission-outline {
    position: relative;
    & > div {
      // border: thin dotted red;
      position: absolute;
      display: inline-block;
      width: 105%;
      left: -0.25em;
      top: -0.7em;
      & > svg {
        fill: none;
        stroke: #fff;
        stroke-width: 10;
      }
    }
  }
  .page-02-images {
    text-align: center;
    padding: 1.5em;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      display: inline-block;
      width: 100%;
      height: 100%;
      max-width: 560px;
      max-height: 310px;
      // border: thin dotted red;
    }

    #fly-house-wrapper {
      margin: auto;
    }
  }
  .position {
    position: relative;
    // position: absolute;
    // bottom: 1em;
    // right: 1em;
    // max-width: 200px;
    font-size: 1.35em;
    line-height: 114%;
    letter-spacing: -0.01em;
    //text-align: left;
    color: $text-white;
    margin: 4em 0 0 40%;
    max-width: 12em;
  }

  .box-building {
    position: absolute;
  }
}

@media (min-width: 961px) {
  #home-page-02 {
    .mission {
      padding: 0 30% 0 20px;
    }
    .mission-outline {
      position: relative;
      & > div {
        // border: thin dotted red;
        position: absolute;
        display: inline-block;
        width: 100%;
        left: -5px;
        top: 10px;
        & > svg {
          fill: none;
          stroke: #fff;
          stroke-width: 4;
        }
      }
    }
    .position {
      position: absolute;
      bottom: 1em;
      right: 1em;
      max-width: 300px;
      font-size: 1.75em;
      line-height: 114%;
      letter-spacing: -0.01em;
      text-align: left;
      color: $text-white;
    }
  }
}

#fly-house-wrapper {
  opacity: 0;
}
.carousel__slide--active {
  #fly-house-wrapper {
    opacity: 1;
  }
}
</style>
