<template>
  <a
    id="contact-map-01"
    href="https://yandex.ru/maps/213/moscow/house/2_y_babyegorodskiy_pereulok_29/Z04YcAdoQEQEQFtvfXtycn5nYA==/?ll=37.609020%2C55.733162&z=17"
    target="_blank"
    ><span class="address">Москва, 2-й Бабьегородский переулок, 29</span></a
  >
</template>

<script>
export default {
  name: "ContactMap01",
};
</script>

<style lang="scss">
#contact-map-01 {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 2.189em;
  padding: 3.125em 3.5625em;
  max-width: 520px;
  max-height: 240px;
  background-image: url(../../../assets/maps/bab.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  .address {
    position: absolute;
    display: inline-block;
    width: 45%;
    height: 30%;
    color: #ffffff;
    background-color: #2c50e6;
    border-radius: 0.75em;
    padding: 0.5em;
    margin: 1.8em;
    font-weight: 400;
    font-size: 0.9em;
    bottom: -1em;
    right: -0.5em;
    &:before {
      content: "";
      display: block;
      height: 22px;
      width: 18px;
      background-image: url(../../../assets/images/v2/Locations.svg);
      margin-bottom: 1em;
    }
  }
}
</style>
