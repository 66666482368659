export default [
  {
    title: "Дизайн",
    count: "нет вакансий",
    color: "#3143bd",
  },
  {
    title: "Бизнес-аналитика",
    count: "нет вакансий",
    color: "#ff8a71",
  },
  {
    title: "Разработка",
    count: "6 вакансий",
    color: "#486dd5",
    data: [
      { title: "Тестировщик", url: "/opening/application-tester.html" },
      {
        title: "Специалист по базам данных",
        url: "/opening/db-engineer.html",
      },
      { title: "DevOps", url: "/opening/dev-ops.html" },
      {
        title: "Бэкенд-разработчик",
        url: "/opening/developer-backend.html",
      },
      {
        title: "Фронтенд-разработчик",
        url: "/opening/developer-frontend.html",
      },
      {
        title: "Технический писатель",
        url: "/opening/technical-author.html",
      },
    ],
  },
  {
    title: "Маркетинг",
    count: "нет вакансий",
    color: "#4b7a7e",
  },
  {
    title: "Аналитика",
    count: "1 вакансия",
    color: "#3143bd",
    data: [{ title: "Аналитик данных", url: "/opening/data-analyst.html" }],
  },
  {
    title: "Другое",
    count: "нет вакансий",
    color: "#486dd5",
  },
];
