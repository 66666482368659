<template>
  <div class="home-page" id="home-page-05">
    <contacts-page />
  </div>
</template>

<script>
import ContactsPage from "../pages/contacts";

export default {
  name: "HomePage05",
  components: { ContactsPage },
};
</script>

<style lang="scss">
.home-page {
  display: inline-block;
  height: 100%;
  width: 100%;
}

#home-page-05 {
  text-align: left;
  #contacts {
    padding: 0;
    background: transparent;
    #contacts-main {
      border: thin solid #2c50e6;
    }
    .address {
      width: 55%;
      &::before {
        margin-bottom: 0.5em;
      }
    }
  }
}
</style>
