<template>
  <div id="home" @wheel="onWheel">
    <carousel
      :transition="500"
      :mute="false"
      :page-ratio="scrollRatio"
      v-model="currentSlide"
    >
      <slide :key="1">
        <slide-01>
          <template v-slot:left>
            <component :is="pages[0].left" />
          </template>
          <template v-slot:right>
            <component
              ref="page-01"
              :is="pages[0].right"
              @next-slide="onNextSlide"
            />
          </template>
        </slide-01>
      </slide>
      <slide :key="2">
        <slide-02 @next-slide="onNextSlide">
          <component ref="page-02" :is="pages[1]" />
        </slide-02>
      </slide>
      <slide :key="3">
        <slide-03 @next-slide="onNextSlide">
          <component ref="page-03" :is="pages[2]" />
        </slide-03>
      </slide>
      <slide :key="4">
        <slide-04 @next-slide="onNextSlide">
          <component ref="page-04" :is="pages[3]" />
        </slide-04>
      </slide>
      <!--slide :key="5">
        <slide-05 @next-slide="onNextSlide">
          <component ref="page-05" :is="pages[4]" />
        </slide-05>
      </slide-->
      <template #addons> </template>
    </carousel>
    <top-menu :current-slide="currentSlide" />
  </div>
</template>

<script>
import numeral from "numeral";
import { Carousel, Slide } from "@oisrf/vue3-carousel";
import TopMenu from "@/components/pages/top-menu";
import Slide01 from "./page-01";
import Slide02 from "./page-02";
import Slide03 from "./page-03";
import Slide04 from "./page-04";
// import Slide05 from "./page-05";

const calculateScrollRatio = () =>
  window.innerWidth <= 960 ? 1 : 1 - 160 / 1400;

export default {
  name: "SlideshowLayout",
  data() {
    return {
      data: ["Slide01", "Slide02", "Slide03", "Slide04"], // "Slide05"],
      currentSlide: 0,
      scrollRatio: calculateScrollRatio(),
      wheelTimeout: null,
      wheelDirection: null,
    };
  },
  methods: {
    onWheel({ deltaY }) {
      console.debug("SlideshowLayout::onWheel", deltaY);
      clearTimeout(this.wheelTimeout);
      if (deltaY > 0) {
        if (this.currentSlide < 3)
          // 5)
          this.wheelTimeout = setTimeout(() => {
            this.currentSlide++;
          }, 350);
      } else if (deltaY < 0) {
        if (this.currentSlide > 0)
          this.wheelTimeout = setTimeout(() => {
            this.currentSlide--;
          }, 350);
      }
    },
    onPrevSlide() {
      if (this.currentSlide > 0) this.currentSlide--;
    },
    onNextSlide() {
      console.debug("onNextSlide");
      if (this.currentSlide < this.data.length - 1) this.currentSlide++;
    },
    resize() {
      this.scrollRatio = calculateScrollRatio();
    },
  },
  watch: {
    currentSlide(currentSlide) {
      const page = this.$refs[`page-${numeral(currentSlide + 1).format("00")}`];
      if (page && page.onPageShow) page.onPageShow();
    },
  },
  mounted() {
    this.$eventBus.on("resize", this.resize);
    setTimeout(() => this.resize());
  },
  unmounted() {
    this.$eventBus.off("resize", this.resize);
  },
  components: {
    Carousel,
    Slide,
    Slide01,
    Slide02,
    Slide03,
    Slide04,
    // Slide05,
    TopMenu,
  },
};
</script>

<style lang="scss">
@import "@oisrf/vue3-carousel/dist/carousel.css";

#home {
  margin: 0;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  display: block;

  .carousel {
    height: 100%;
    width: 100%;
    .carousel__viewport {
      height: 100%;
    }
    .carousel__track {
      height: 100%;
    }
    .carousel__slide {
      height: 100%;
    }
  }
}
</style>
